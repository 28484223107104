// Decorador que nos permite inyectar los servicios por inyeccion de dependencia
import { Injectable } from '@angular/core';
//
import { HttpClient, HttpHeaders } from '@angular/common/http';

// sirve para poder capturar lo que responden las peticiones ajax
import 'rxjs/add/operator/map';

import { Observable } from 'rxjs/Observable';

// Referencia al fichero de configuracion global.ts
import {GLOBAL} from './global';

@Injectable()
export class ClientAddressService {
  url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url + '/v1';
  }

  create(clientAddress) {
    let json = JSON.stringify(clientAddress);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/address/create', json, { headers });
  }

  retrieve(id) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/address/retrieve/' + id, '', { headers });
  }

  retrieveAll() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/address/retrieve-all', '', { headers });
  }

  update(clientAddress) {
    let json = JSON.stringify(clientAddress);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/address/update/' + clientAddress.id, json, { headers });
  }

  remove(clientAddress) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/address/remove/' + clientAddress.id, '', { headers });
  }
}
