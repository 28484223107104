// Decorador que nos permite inyectar los servicios por inyeccion de dependencia
import { Injectable } from '@angular/core';
//
import { HttpClient, HttpHeaders } from '@angular/common/http';

// sirve para poder capturar lo que responden las peticiones ajax
import "rxjs/add/operator/map";

import { Observable } from 'rxjs/Observable';

// Referencia al fichero de configuracion global.ts
import { GLOBAL } from './global';

@Injectable()
export class OrderService {
  url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url + '/v1';
  }

  create(order) {
    let json = JSON.stringify(order);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/order/create', json, { headers });
  }

  batchCreate(orders) {
    let json = JSON.stringify(orders);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/order/batch-create', json, { headers });
  }

  retrieve(id) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/order/retrieve/' + id, '', { headers });
  }

  retrieveAll(trackingId = '', shippmentId = '', clientOtderId = '', fromDate = null, toDate = null, page = 1) {
    let queryStringVars = [];
    let queryString = '';

    if (trackingId !== '') {
      queryStringVars.push('tracking_id=' + trackingId);
    }
    if (shippmentId !== '') {
      queryStringVars.push('shippment_id=' + shippmentId);
    }
    if (clientOtderId !== '') {
      queryStringVars.push('client_order_id=' + clientOtderId);
    }
    if (fromDate !== null) {
      queryStringVars.push('from_date='+fromDate);
    }
    if (toDate !== null) {
      queryStringVars.push('to_date='+toDate);
    }

    queryStringVars.push('page='+page);

    queryString = queryStringVars.join('&');

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/order/retrieve-all?' + queryString, '', { headers });
  }
}
