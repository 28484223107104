import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';
import { ClientAddressService } from '../services/client_address.service';
import { ClientAddress } from '../models/client_address';

@Component({
  selector: 'client-address-edit',
  templateUrl: '../views/client_address.new.html',
  providers: [
    ClientService,
    ClientAddressService
  ]
})

export class ClientAddressEditComponent implements OnInit {
  clientAddress: ClientAddress;
  defaultClientAddress: ClientAddress;
  wasDefaultClientAddress: boolean;
  errorMsg;
  processing: boolean;
  loadingData: boolean;
  pageTitle: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _clientAddressService: ClientAddressService,
  ) {
    this.pageTitle = 'Editar dirección';
    this.processing = false;
    this.loadingData = false;
    this.defaultClientAddress = this._authService.getClientIdentity().default_client_address;
    this.wasDefaultClientAddress = false;
  }

  ngOnInit() {
    this.getClientAddress();
  }

  getClientAddress() {
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      let id = +params['id'];

      this._clientAddressService.retrieve(id).subscribe(
        (response: any) => {
          this.loadingData = false;

          this.clientAddress = new ClientAddress().hydrateWith(response.data);

          this.clientAddress.isDefault = this.clientAddress.id === this.defaultClientAddress.id;
          this.wasDefaultClientAddress = this.clientAddress.isDefault;
        },
        (errorResponse: any) => {
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      )
    });
  }

  onSubmit(clientAddressEditForm: NgForm) {
      if (this.processing) {
          this.errorMsg = 'Procesando, por favor espere';
          return;
      }

      clientAddressEditForm.form.disable();
      this.processing = true;
      this.errorMsg = '';

      this._clientAddressService.update(this.clientAddress).subscribe(
          (response: any) => {
              let isDefault = this.clientAddress.isDefault;
              this.errorMsg = '';

              this.clientAddress = new ClientAddress().hydrateWith(response.data);

              if (isDefault) {
                  let clientIdentity = this._authService.getClientIdentity();
                  clientIdentity.default_client_address = this.clientAddress;
                  this._authService.setClientIdentity(clientIdentity);
              }

              this._router.navigate(['/client/detail']);
          },
          (errorResponse: any) => {
              this.processing = false;

              clientAddressEditForm.form.enable();

              this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
          }
      );
  }
}
