import { Hydratable } from './hydratable';
import { Service } from './service';
import { ClientAddress } from './client_address';
import { DeliveryMode } from './delivery_mode';
import { Receiver } from './receiver';
import { OrderRate } from './order_rate';
import { Shipping } from './shipping';

export class Order implements Hydratable {
  id: number;
  service: Service;
  shippings: Array<Shipping>;
  clientAddress: ClientAddress;
  deliveryMode: DeliveryMode;
  receiver: Receiver;
  trackingId: string;
  clientOrderId: string;
  orderDate;
  pactedDate;
  channel: string;
  type: string;
  deliveryShift: number;
  orderRate: OrderRate;

  hydrateWith(input: any): this {
    Object.assign(this, input);

    this.service = input.service ? new Service().hydrateWith(input.service) : null;
    this.shippings = input.shippings.map(shipping => new Shipping().hydrateWith(shipping));
    this.clientAddress = new ClientAddress().hydrateWith(input.clientAddress);
    this.deliveryMode = new DeliveryMode().hydrateWith(input.deliveryMode);
    this.receiver = new Receiver().hydrateWith(input.receiver);
    this.orderRate = new OrderRate().hydrateWith(input.orderRate);

    return this;
  }
}
