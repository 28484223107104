import { OrderBatchComponent } from './components/order.batch.component';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login.component';
import { ActivateComponent } from './components/activate.component';

import { NotFoundComponent } from './components/not_found.component';

import { DashboardComponent } from './components/dashboard.component';

import { UserDetailComponent } from './components/user.detail.component';
import { UserEditComponent } from './components/user.edit.component';

import { ClientDetailComponent } from './components/client.detail.component';
import { ClientEditComponent } from './components/client.edit.component';

import { ClientAddressDetailComponent } from './components/client_address.detail.component';
import { ClientAddressNewComponent } from './components/client_address.new.component';
import { ClientAddressEditComponent } from './components/client_address.edit.component';

import { ProductNewComponent } from './components/product.new.component';
import { ProductDetailComponent } from './components/product.detail.component';
import { ProductEditComponent } from './components/product.edit.component';
import { ProductListComponent } from './components/product.list.component';
import { ProductBatchComponent } from './components/product.batch.component';

import { PackagingNewComponent } from './components/packaging.new.component';
import { PackagingDetailComponent } from './components/packaging.detail.component';
import { PackagingEditComponent } from './components/packaging.edit.component';
import { PackagingListComponent } from './components/packaging.list.component';
import { PackagingBatchComponent } from './components/packaging.batch.component';

import { ProductPackagingNewComponent } from './components/product_packaging.new.component';
import { ProductPackagingEditComponent } from './components/product_packaging.edit.component';

import { OrderNewComponent } from './components/order.new.component';
import { OrderDetailComponent } from './components/order.detail.component';
import { OrderListComponent } from './components/order.list.component';

import { AuthGuard } from './auth/auth.guard';
import { AuthNotGuard } from './auth/auth-not.guard';

const appRoutes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthNotGuard] },
  { path: 'activate/:userId/:token', component: ActivateComponent, canActivate: [AuthNotGuard] },
  { path: 'user/detail', component: UserDetailComponent, canActivate: [AuthGuard] },
  { path: 'user/edit', component: UserEditComponent, canActivate: [AuthGuard] },
  { path: 'client/detail', component: ClientDetailComponent, canActivate: [AuthGuard] },
  { path: 'client/edit', component: ClientEditComponent, canActivate: [AuthGuard] },
  { path: 'client-address/new', component: ClientAddressNewComponent, canActivate: [AuthGuard] },
  { path: 'client-address/detail/:id', component: ClientAddressDetailComponent, canActivate: [AuthGuard] },
  { path: 'client-address/edit/:id', component: ClientAddressEditComponent, canActivate: [AuthGuard] },
  { path: 'products', component: ProductListComponent, canActivate: [AuthGuard] },
  { path: 'product/new', component: ProductNewComponent, canActivate: [AuthGuard] },
  { path: 'product/batch', component: ProductBatchComponent, canActivate: [AuthGuard] },
  { path: 'product/:id', component: ProductDetailComponent, canActivate: [AuthGuard] },
  { path: 'product/edit/:id', component: ProductEditComponent, canActivate: [AuthGuard] },
  { path: 'packagings', component: PackagingListComponent, canActivate: [AuthGuard] },
  { path: 'packaging/new', component: PackagingNewComponent, canActivate: [AuthGuard] },
  { path: 'packaging/batch', component: PackagingBatchComponent, canActivate: [AuthGuard] },
  { path: 'packaging/:id', component: PackagingDetailComponent, canActivate: [AuthGuard] },
  { path: 'packaging/edit/:id', component: PackagingEditComponent, canActivate: [AuthGuard] },
  { path: 'product-packaging/new/:base/:id', component: ProductPackagingNewComponent, canActivate: [AuthGuard] },
  { path: 'product-packaging/edit/:id', component: ProductPackagingEditComponent, canActivate: [AuthGuard] },
  { path: 'orders', component: OrderListComponent, canActivate: [AuthGuard] },
  { path: 'order/new', component: OrderNewComponent, canActivate: [AuthGuard] },
  { path: 'order/batch', component: OrderBatchComponent, canActivate: [AuthGuard] },
  { path: 'order/:id', component: OrderDetailComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
