import { Hydratable } from './hydratable';
import { Product } from './product';
import { Packaging } from './packaging';

export class ProductPackaging implements Hydratable {
  id: number;
  product: Product;
  packaging: Packaging;
  maxUnits: number;
  active: boolean;

  hydrateWith(input: any): this {
    Object.assign(this, input);

    this.product = new Product().hydrateWith(input.product);
    this.packaging = new Packaging().hydrateWith(input.packaging);

    return this;
  }
}
