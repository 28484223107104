import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appDragDrop]'
})
export class DragDropDirective {

  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('class') public cssClass = 'uploadfilecontainer mb-3'

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.cssClass = 'uploadfilecontainer mb-3 dragover';
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.cssClass = 'uploadfilecontainer mb-3';
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.cssClass = 'uploadfilecontainer mb-3';
    let files = evt.dataTransfer.files;

    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
  }

}
