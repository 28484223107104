// Decorador que nos permite inyectar los servicios por inyeccion de dependencia
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';

// Referencia al fichero de configuracion global.ts
import { GLOBAL } from '../services/global';

import { User } from '../models/user';
import { Client } from '../models/client';

@Injectable()
export class AuthService {
  public url: string;

  public userIdentity$: Subject<User> = new Subject<User>();
  public clientIdentity$: Subject<Client> = new Subject<Client>();

  constructor(private _http: HttpClient) {
    this.url = GLOBAL.url + '/v1';
  }

  login(username: string, password: string) {
    let json = {
      username: username,
      password: password
    };
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/auth/login', json, { headers });
  }

  logout() {
    let json = {
      user_id: this.getUserIdentity().id,
      refresh_token: this.getRefreshToken()
    };
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/auth/logout', json, { headers });
  }

  refreshToken() {
    if (!this.isLoggedIn()) {
      return throwError('El usuario no se encuentra logueado');
    }

    let json = {
      user_id: this.getUserIdentity().id,
      refresh_token: this.getRefreshToken()
    };
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/auth/refresh', json, { headers });
  }

  activate(user: User, activationToken: string) {
    let json = {
      user_id: user.id,
      password: user.password,
      activation_token: activationToken
    };
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/auth/activate', json, { headers });
  }

  clearStorage() {
    this.removeToken();
    this.removeRefreshToken();
    this.removeUserIdentity();
    this.removeClientIdentity();
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  getRefreshToken() {
    return localStorage.getItem('REFRESH_TOKEN');
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('REFRESH_TOKEN', refreshToken);
  }

  removeRefreshToken() {
    localStorage.removeItem('REFRESH_TOKEN');
  }

  getUserIdentity() {
    return JSON.parse(localStorage.getItem('user.identity'));
  }

  setUserIdentity(user) {
    this.userIdentity$.next(user);
    localStorage.setItem('user.identity',JSON.stringify(user));
  }

  removeUserIdentity() {
    localStorage.removeItem('user.identity');
    this.userIdentity$.next(undefined);
  }

  getClientIdentity() {
    return JSON.parse(localStorage.getItem('client.identity'));
  }

  removeClientIdentity() {
    localStorage.removeItem('client.identity');
    this.clientIdentity$.next(undefined);
  }

  setObservables() {
    let userIdentity = this.getUserIdentity();
    let clientIdentity = this.getClientIdentity();

    this.userIdentity$.next(userIdentity);
    this.clientIdentity$.next(clientIdentity);
  }

  setClientIdentity(client) {
    this.clientIdentity$.next(client);
    localStorage.setItem('client.identity',JSON.stringify(client));
  }

  isLoggedIn() {
    let accessToken = this.getToken();
    let refreshToken = this.getRefreshToken();
    let userIdentity = this.getUserIdentity();
    let clientIdentity = this.getClientIdentity();

    return !!(accessToken && refreshToken && userIdentity && clientIdentity);
  }
}
