import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { PackagingService } from '../services/packaging.service';
import { Packaging } from '../models/packaging';

@Component({
  selector: 'packaging-new',
  templateUrl: '../views/packaging.new.html',
  providers: [PackagingService]
})

export class PackagingNewComponent implements OnInit {
  packaging: Packaging;
  errorMsg;
  loadingData: boolean;
  processing: boolean;
  pageTitle: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _packagingService: PackagingService
  ) {
    this.pageTitle = 'Crear paquetería';
    this.loadingData = false;
    this.processing = false;
  }

  ngOnInit() {
    this.packaging = new Packaging();
    this.packaging.active = true;
    this.packaging.value = 0;
    this.packaging.publicPrice = 0;
    this.packaging.height = 0;
    this.packaging.length = 0;
    this.packaging.dimensionsScope = 0;
    this.packaging.weightScope = 0;
  }

  onSubmit(packagingNewForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    packagingNewForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._packagingService.create(this.packaging).subscribe(
      (response: any) => {
        this.errorMsg = '';

        this._router.navigate(['/packagings']);
      },
      (errorResponse: any) => {
        this.processing = false;

        packagingNewForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
