import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { OrderService } from '../services/order.service';
import { Order } from '../models/order';

@Component({
  selector: 'order-list',
  templateUrl: '../views/order.list.html',
  providers: [OrderService]
})

export class OrderListComponent implements OnInit {
  errorMsg;
  orders: Array<Order>;
  processing: boolean;
  loadingData: boolean;
  currentPage;
  paginationData;

  clientOrderId;
  trackingId;
  shippmentId;
  fromDate;
  toDate;
  trackingIdSbmt;
  shippmentIdSbmt;
  clientOrderIdSbmt;
  fromDateSbmt;
  toDateSbmt;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _orderService: OrderService
  ) {
    this.orders = [];
    this.clientOrderId = '';
    this.fromDate = null;
    this.toDate = null;
    this.clientOrderIdSbmt = '';
    this.fromDateSbmt = null;
    this.toDateSbmt = null;
    this.loadingData = false;
    this.processing = false;
    this.errorMsg = '';
  }

  ngOnInit() {
    this.currentPage = 1;
    this.getAllOrders();
  }

  getAllOrders() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.processing = true;
    this.errorMsg = '';
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      this._orderService.retrieveAll(this.trackingId, this.shippmentIdSbmt, this.clientOrderIdSbmt, this.fromDateSbmt, this.toDateSbmt, this.currentPage).subscribe(
        (response: any) => {
          this.processing = false;
          this.errorMsg = '';

          this.paginationData = response.paginationData;

          this.orders = response.data.map(order => new Order().hydrateWith(order));

          this.loadingData = false;
        },
        (errorResponse: any) => {
          this.processing = false;
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  searchOrders() {
    this.trackingIdSbmt = this.trackingId;
    this.shippmentIdSbmt = this.shippmentId;
    this.clientOrderIdSbmt = this.clientOrderId;
    this.fromDateSbmt = this.fromDate;
    this.toDateSbmt = this.toDate;
    this.currentPage = 1;
    this.getAllOrders();
  }

  goToPage(page) {
    this.currentPage = page;
    this.trackingId = this.trackingIdSbmt;
    this.shippmentId = this.shippmentIdSbmt;
    this.clientOrderId = this.clientOrderIdSbmt;
    this.fromDate = this.fromDateSbmt;
    this.toDate = this.toDateSbmt;
    this.getAllOrders();
  }
}
