import { Hydratable } from './hydratable';

export class User implements Hydratable {
  id: number;
  username: string;
  name: string;
  surname: string;
  email: string;
  password: string;

  hydrateWith(input: any): this {
    return Object.assign(this, input);
  }
}
