import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ProductService } from '../services/product.service';
import { Product } from '../models/product';

@Component({
  selector: 'product-new',
  templateUrl: '../views/product.new.html',
  providers: [ProductService]
})

export class ProductNewComponent implements OnInit {
  product: Product;
  errorMsg;
  processing: boolean;
  loadingData: boolean;
  pageTitle: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productService: ProductService
  ) {
    this.pageTitle = 'Crear producto';
    this.processing = false;
    this.loadingData = false;
  }

  ngOnInit() {
    this.product = new Product();
    this.product.categoryId = 1;
    this.product.active = true;
  }

  onSubmit(productNewForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    productNewForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._productService.create(this.product).subscribe(
      (response: any) => {
        this.errorMsg = '';

        this.product = response.data;

        this._router.navigate(['/products']);
      },
      (errorResponse: any) => {
        this.processing = false;

        productNewForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
