import { CsvService } from './../services/csv.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ProductService } from '../services/product.service';
import { Product } from '../models/product';

@Component({
  selector: 'product-batch',
  templateUrl: '../views/product.batch.html',
  providers: [
    ProductService,
    CsvService
  ]
})

export class ProductBatchComponent implements OnInit {

  csvHeader: string;
  csvFieldCount: number;
  loadingExampleCsv: boolean;
  loadingData: boolean;
  processing: boolean;
  errorMsg: string;
  products: Array<Product>;
  savedProducts: Array<Product>;
  file: File;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productService: ProductService,
    private _csvService: CsvService
  ) {
    this.processing = false;
    this.loadingData = false;
  }

  ngOnInit() {
    this.errorMsg = '';
    this.resetBatch();
    this.loadExampleCsv();
  }

  loadExampleCsv() {
    this.loadingExampleCsv = true;

    this._csvService.getProductsCsv().subscribe(
      (lines: string[]) => {
        this.loadingExampleCsv = false;
        this.csvHeader = lines[0];
        this.csvFieldCount = this.csvHeader.split(';').length;
      }
    );
  }

  processFiles(fileList: FileList) {
    this.resetBatch();

    this.file = fileList[0];
    
    console.log(fileList);
    console.log(this.file);
    console.log(this.file.type);
    console.log(this.file.name.split('.').pop());

    if (this.file.type != 'text/csv' && this.file.name.split('.').pop() != 'csv') {
      this.errorMsg = 'El archivo seleccionado no es un CSV';
      this.resetBatch();
      return;
    }

    this.readFileContent(this.file).then((content:string) => {
      this.processCSV(content);
    }).catch(error => console.log(error));
  }

  readFileContent(file: File) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = event => resolve((event.target as FileReader).result);
      reader.onerror = error => reject(error);
      reader.readAsText(file);
    });
  }

  processCSV(content: string) {
    this.errorMsg = '';
    let lines = content.split(/\r\n|\n/);
    let headerString = lines.shift();
    let headers = headerString.split(';');

    if (headers.length != 12) {
      this.errorMsg = 'El encabezado no es válido (debe tener 12 campos)';
      return;
    }

    if (lines.length > 1000) {
      this.errorMsg = 'El lote debe tener como máximo 1000 productos';
      return;
    }

    let invalidLines = 0;
    let products = [];

    for (let line of lines) {
      if (line == "\n" || line.trim().length == 0) {
        continue;
      }
      let data = line.split(';');

      if (data.length != 12) {
        invalidLines++;
        continue;
      }

      let product = new Product().hydrateWith({
        category_id: 1,
        name: data[0].trim(),
        description: data[1].trim(),
        sku: data[2].trim(),
        barcode_symbology: data[3].trim(),
        value: parseFloat(data[4]),
        public_price: parseFloat(data[5]),
        height: parseInt(data[6]),
        length: parseInt(data[7]),
        width: parseInt(data[8]),
        weight: parseInt(data[9]),
        minimum_purchase_quantity: parseInt(data[10]),
        purchase_quantity: parseInt(data[11]),
        active: true
      });

      products.push(product);
    }

    if (invalidLines > 0) {
      this.errorMsg = 'Líneas no válidas: ' + invalidLines;
      return;
    }

    if (!products.length) {
      this.errorMsg = 'La estructura del CSV es correcta pero no contiene ningún producto.';
      return;
    }

    this.errorMsg = '';

    this.products = products;
  }

  resetBatch() {
    this.file = undefined;
    this.products = [];
    this.savedProducts = [];
  }

  cancel() {
    this.errorMsg = '';
    this.resetBatch();
  }

  saveProductBatch() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.loadingData = true;
    this.processing = true;
    this.errorMsg = '';

    this._productService.batchCreate(this.products).subscribe(
      (response: any) => {
        this.loadingData = false;
        this.processing = false;
        this.errorMsg = '';

        this.resetBatch();

        this.savedProducts = response.data.map(product => new Product().hydrateWith(product));
      },
      (errorResponse: any) => {
        this.loadingData = false;
        this.processing = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
