import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';
import { ClientAddressService } from '../services/client_address.service';
import { ClientAddress } from '../models/client_address';

@Component({
  selector: 'client-address-new',
  templateUrl: '../views/client_address.new.html',
  providers: [ClientAddressService]
})

export class ClientAddressNewComponent implements OnInit {
  clientAddress: ClientAddress;
  wasDefaultClientAddress = false;
  errorMsg;
  processing: boolean;
  loadingData: boolean;
  pageTitle: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _clientAddressService: ClientAddressService
  ) {
    this.pageTitle = 'Crear dirección';
    this.processing = false;
    this.loadingData = false;
  }

  ngOnInit() {
    this.clientAddress = new ClientAddress();

    this.clientAddress.isDefault = false;
  }

  onSubmit(clientAddressNewForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    clientAddressNewForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._clientAddressService.create(this.clientAddress).subscribe(
      (response: any) => {
        let isDefault = this.clientAddress.isDefault;
        this.errorMsg = '';

        this.clientAddress = new ClientAddress().hydrateWith(response.data);

        if (isDefault) {
          let clientIdentity = this._authService.getClientIdentity();
          clientIdentity.default_client_address = this.clientAddress;
          this._authService.setClientIdentity(clientIdentity);
        }

        this._router.navigate(['/client/detail']);
      },
      (errorResponse: any) => {
        this.processing = false;

        clientAddressNewForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
