import { Component, Input, OnChanges , Output , EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: '../views/pagination.html',
})
export class PaginationComponent {
  @Input() format;
  @Input() paginationData;

  @Output() onPageChange: EventEmitter<number> = new EventEmitter();

  onClickPage(page) {
    this.onPageChange.emit(page);
  }
}
