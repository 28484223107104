import { Hydratable } from './hydratable';

export class PackagingInventory implements Hydratable {
  id: number;
  realStock: number;
  availableStock: number;
  reservedStock: number;
  unsuitableStock: number;
  freeStock: number;
  freeFrontStock: number;
  reservedFrontStock: number;

  hydrateWith(input: any): this {
    return Object.assign(this, input);
  }

  freeStockCritical() {
      return this.freeStock > 0 && this.freeStock < 10;
  }

  freeStockOk() {
      return this.freeStock >= 10;
  }

  noFreeStock() {
      return this.freeStock === 0;
  }
}
