import { Injectable } from '@angular/core';

import { Router} from '@angular/router'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject, throwError } from 'rxjs';
import { switchMap, filter, take, catchError} from 'rxjs/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _router: Router,
    private _authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this._authService.getToken();

    token = token ? token : "";

    req = this.addToken(req, token);

    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        Authorization: token
      }
    });
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._authService.refreshToken().pipe(
        catchError((error) => {
          this.isRefreshing = false;

          if (error instanceof HttpErrorResponse && error.status == 403) {
            let requiredUrl = this._router.routerState.snapshot.url;

            this._authService.clearStorage();

            this._router.navigate(['/login'], {
              queryParams: {
                returnUrl: requiredUrl
              }
            });
          }

          return throwError(error);
        }),
        switchMap((response: any) => {
          let newAccessToken = response.accessToken;

          this._authService.setToken(newAccessToken);

          this.isRefreshing = false;
          this.refreshTokenSubject.next(newAccessToken);
          return next.handle(this.addToken(req, newAccessToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((token: string) => {
          return next.handle(this.addToken(req, token))
        })
      );
    }
  }
}
