import { Hydratable } from './hydratable';
import { TemplateCosts } from './template_costs';
import { ClientConfig } from './client_config';

export class Client implements Hydratable {
  id: number;
  name: string;
  cuit: string;
  prefix: string;
  clientConfig: ClientConfig;
  templateCosts: TemplateCosts;

  hydrateWith(input: any): this {
    Object.assign(this, input);

    this.clientConfig = new ClientConfig().hydrateWith(input.clientConfig);
    this.templateCosts = new TemplateCosts().hydrateWith(input.templateCosts);

    return this;
  }
}
