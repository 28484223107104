import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { ProductService } from '../services/product.service';
import { Product } from '../models/product';

@Component({
  selector: 'product-list',
  templateUrl: '../views/product.list.html',
  providers: [ProductService]
})

export class ProductListComponent implements OnInit {
  errorMsg;
  products: Array<Product>;
  processing: boolean;
  loadingData: boolean;
  currentPage;
  paginationData;

  search_active;
  search_term;
  search_active_sbmt;
  search_term_sbmt;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productService: ProductService
  ) {
    this.products = [];
    this.search_active = null;
    this.search_term = '';
    this.search_active_sbmt = null;
    this.search_term_sbmt = '';
    this.loadingData = false;
    this.processing = false;
    this.errorMsg = '';
  }

  ngOnInit() {
    this.currentPage = 1;
    this.getAllProducts();
  }

  getAllProducts() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.processing = true;
    this.errorMsg = '';
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      this._productService.retrieveAll(this.search_active_sbmt, this.search_term_sbmt, this.currentPage).subscribe(
        (response: any) => {
          this.processing = false;
          this.errorMsg = '';

          this.paginationData = response.paginationData;

          this.products = response.data.map(product => new Product().hydrateWith(product));

          this.loadingData = false;
        },
        (errorResponse: any) => {
          this.processing = false;
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  searchProducts() {
    this.search_active_sbmt = this.search_active;
    this.search_term_sbmt = this.search_term;
    this.currentPage = 1;
    this.getAllProducts();
  }

  goToPage(page) {
    this.currentPage = page;
    this.search_active = this.search_active_sbmt;
    this.search_term = this.search_term_sbmt;
    this.getAllProducts();
  }
}
