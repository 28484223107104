import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ProductPackagingService } from '../services/product_packaging.service';
import { ProductService } from '../services/product.service';
import { PackagingService } from '../services/packaging.service';
import { ProductPackaging } from '../models/product_packaging';
import { Product } from '../models/product';
import { Packaging } from '../models/packaging';

@Component({
  selector: 'product_packaging-edit',
  templateUrl: '../views/product_packaging.edit.html',
  providers: [
    ProductPackagingService,
    ProductService,
    PackagingService
  ]
})

export class ProductPackagingEditComponent implements OnInit {
  productPackaging: ProductPackaging;
  products: Array<Product>;
  packagings: Array<Packaging>;
  errorMsg;
  processing: boolean;
  loadingData: boolean;
  pageTitle: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productPackagingService: ProductPackagingService,
    private _productService: ProductService,
    private _packagingService: PackagingService
  ) {
    this.pageTitle = 'Asignar producto/paquetería';
    this.processing = false;
    this.loadingData = false;
    this.products = [];
    this.packagings = [];
  }

  ngOnInit() {
    this.getProductPackaging();
    this.getProducts();
    this.getPackagings();
  }

  onSubmit(productPackagingEditForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    productPackagingEditForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._productPackagingService.update(this.productPackaging).subscribe(
      (response: any) => {
        this.errorMsg = '';

        this._router.navigate(['/products']);
      },
      (errorResponse: any) => {
        this.processing = false;

        productPackagingEditForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  getProductPackaging() {
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      let id = +params['id'];

      this._productPackagingService.retrieve(id).subscribe(
        (response: any) => {
          this.productPackaging = new ProductPackaging().hydrateWith(response.data);

          this.loadingData = false;
        },
        (errorResponse: any) => {
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  getProducts() {
    this._productService.retrieveAll().subscribe(
      (response: any) => {
        this.products = response.data.map(product => new Product().hydrateWith(product));
      },
      (errorResponse: any) => {
        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  getPackagings() {
    this._packagingService.retrieveAll().subscribe(
      (response: any) => {
        this.packagings = response.data.map(packaging => new Packaging().hydrateWith(packaging));
      },
      (errorResponse: any) => {
        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
