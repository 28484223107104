import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';
import { ClientService } from '../services/client.service';
import { User } from '../models/user';
import { Client } from '../models/client';

@Component({
    selector: 'login',
    templateUrl: '../views/login.html'
})

export class LoginComponent implements OnInit {
  user: User;
  identity;
  token;
  processing: boolean;
  errorMsg: string;

  returnUrl: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _userService: UserService,
    private _clientService: ClientService
  ) {
    this.user = new User().hydrateWith({
      username: '',
      password: ''
    });

    this.processing = false;
    this.errorMsg = '';
  }

  ngOnInit() {
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit(loginForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    loginForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._authService.login(this.user.username, this.user.password).subscribe(
      (response: any) => {
        const accessToken = response.accessToken;
        const refreshToken = response.refreshToken;

        this.token = accessToken;

        if (this.token.length <= 1) {
          this.errorMsg = 'Ha ocurrido un error';
          return;
        }

        this._authService.setToken(this.token);
        this._authService.setRefreshToken(refreshToken);

        this.identity = new User().hydrateWith(response.user);

        if (!this.identity.id){
          this.errorMsg = 'Ha ocurrido un error';
          return;
        }

        this._authService.setUserIdentity(this.identity);

        const client = new Client().hydrateWith(response.user.client);

        this._authService.setClientIdentity(client);

        this._router.navigateByUrl(this.returnUrl);
      },
      (errorResponse: any) => {
        this.processing = false;

        loginForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
