import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ProductService } from '../services/product.service';
import { Product } from '../models/product';

@Component({
  selector: 'product-edit',
  templateUrl: '../views/product.new.html',
  providers: [ProductService]
})

export class ProductEditComponent implements OnInit {
  product: Product;
  errorMsg;
  processing: boolean;
  loadingData: boolean;
  pageTitle: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productService: ProductService
  ) {
    this.pageTitle = 'Editar producto';
    this.processing = false;
    this.loadingData = false;
  }

  ngOnInit() {
    this.getProduct();
  }

  getProduct() {
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      let id = +params['id'];

      this._productService.retrieve(id).subscribe(
        (response: any) => {
          this.product = new Product().hydrateWith(response.data);

          this.loadingData = false;
        },
        (errorResponse: any) => {
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  onSubmit(productEditForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    productEditForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._productService.update(this.product).subscribe(
      (response: any) => {
        this.errorMsg = '';

        this.product = response.data;

        this._router.navigate(['/products']);
      },
      (errorResponse: any) => {
        this.processing = false;

        productEditForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
