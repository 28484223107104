import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { OrderService } from '../services/order.service';
import { Order } from '../models/order';

@Component({
  selector: 'order-detail',
  templateUrl: '../views/order.detail.html',
  providers: [
    OrderService
  ]
})

export class OrderDetailComponent implements OnInit {
  order: Order;
  errorMsg;
  loadingData: boolean;
  processing: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _orderService: OrderService
  ) {
    this.loadingData = false;
    this.processing = false;
  }

  ngOnInit() {
    this.getOrder();
  }

  getOrder() {
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      let id = +params['id'];

      this._orderService.retrieve(id).subscribe(
        (response: any) => {
          this.order = new Order().hydrateWith(response.data);

          this.loadingData = false;
        },
        (errorResponse: any) => {
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }
}
