import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user';

@Component({
  selector: 'user-detail',
  templateUrl: '../views/user.detail.html'
})

export class UserDetailComponent implements OnInit {
  user: User;
  errorMsg;
  loadingData: boolean;

constructor(
  private _route: ActivatedRoute,
  private _router: Router,
  private _authService: AuthService,
  private _userService: UserService
) {
  this.loadingData = false;
}

ngOnInit() {
  let identity = this._authService.getUserIdentity();

  this.user = new User().hydrateWith(identity);
}
}
