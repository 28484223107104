import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { PackagingService } from '../services/packaging.service';
import {Packaging} from '../models/packaging';

@Component({
    selector: 'packaging-list',
    templateUrl: '../views/packaging.list.html',
    providers: [PackagingService]
})

export class PackagingListComponent implements OnInit {
  errorMsg;
  packagings: Array<Packaging>;
  eiguaPackagings: Array<Packaging>;
  processing: boolean;
  loadingPackagings: boolean;
  loadingEiguaPackagings: boolean;
  currentPage;
  currentPage2;
  paginationData;
  paginationData2;

  search_active;
  search_term;
  search_active_sbmt;
  search_term_sbmt;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _packagingService: PackagingService
  ) {
    this.packagings = [];
    this.eiguaPackagings = [];
    this.search_active = null;
    this.search_term = '';
    this.search_active_sbmt = null;
    this.search_term_sbmt = '';
    this.loadingPackagings = false;
    this.loadingEiguaPackagings = false;
    this.processing = false;
    this.errorMsg = '';
  }

  ngOnInit() {
    this.currentPage = 1;
    this.getAllPackagings();
    this.getAllEiguaPackagings();
  }

  getAllPackagings() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.processing = true;
    this.errorMsg = '';
    this.loadingPackagings = true;

    this._route.params.forEach((params: Params) => {
      let page = +params['page'];

      if (!page) {
          page = 1;
      }

      this._packagingService.retrieveAll(this.search_active, this.search_term, this.currentPage).subscribe(
        (response: any) => {
          this.processing = false;
          this.errorMsg = '';

          this.paginationData = response.paginationData;

          this.packagings = response.data.map(packaging => new Packaging().hydrateWith(packaging));

          this.loadingPackagings = false;
        },
        (errorResponse: any) => {
          this.processing = false;
          this.loadingPackagings = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  getAllEiguaPackagings() {
    this.errorMsg = '';
    this.loadingEiguaPackagings = true;

    this._route.params.forEach((params: Params) => {
      let page = +params['page'];

      if (!page) {
        page = 1;
      }

      this._packagingService.retrieveAllEigua(this.search_term, this.currentPage).subscribe(
        (response: any) => {
          this.errorMsg = '';

          this.paginationData2 = response.paginationData;

          this.eiguaPackagings = response.data.map(packaging => new Packaging().hydrateWith(packaging));

          this.loadingEiguaPackagings = false;
        },
        (errorResponse: any) => {
          this.loadingEiguaPackagings = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  searchPackagings() {
    this.search_active_sbmt = this.search_active;
    this.search_term_sbmt = this.search_term;
    this.currentPage = 1;
    this.getAllPackagings();
  }

  goToPage(page) {
    this.currentPage = page;
    this.search_active = this.search_active_sbmt;
    this.search_term = this.search_term_sbmt;
    this.getAllPackagings();
  }

  goToPage2(page) {
    this.currentPage2 = page;
    this.getAllEiguaPackagings();
  }
}
