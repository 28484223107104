// Decorador que nos permite inyectar los servicios por inyeccion de dependencia
import { Injectable } from '@angular/core';
//
import { HttpClient, HttpHeaders } from '@angular/common/http';

// sirve para poder capturar lo que responden las peticiones ajax
import "rxjs/add/operator/map";

import { Observable } from 'rxjs/Observable';

// Referencia al fichero de configuracion global.ts
import { GLOBAL } from './global';

@Injectable()
export class ProductService {
  url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url + '/v1';
  }

  create(product) {
    let json = JSON.stringify(product);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product/create', json, { headers });
  }

  batchCreate(products) {
    let json = JSON.stringify(products);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product/batch-create', json, { headers });
  }

  retrieve(id) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product/retrieve/' + id, '', { headers });
  }

  retrieveAll(search_active = null, search_term = '', page = null) {
    let queryStringVars = [];
    let queryString = '';

    if (search_active !== null) {
      queryStringVars.push('search_active='+search_active);
    }
    if (search_term !== '') {
      queryStringVars.push('search_term='+search_term);
    }
    if (page !== null) {
      queryStringVars.push('page='+page);
    }

    queryString = queryStringVars.join('&');

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product/retrieve-all?' + queryString, '', { headers });
  }

  update(product) {
    let json = JSON.stringify(product);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product/update/' + product.id, json, { headers });
  }
}
