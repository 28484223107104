import { Hydratable } from './hydratable';

export class Receiver implements Hydratable {
  id: number;
  name: string;
  surname: string;
  phone: string;
  email: string;
  company: string;
  streetName: string;
  streetNumber: string;
  betweenStreet1: string;
  betweenStreet2: string;
  zipcode: string;
  province: string;
  city: string;
  neighborhood: string;
  floor: string;
  apartment: string;
  latitude: number;
  longitude: number;
  document: string;

  hydrateWith(input: any): this {
    return Object.assign(this, input);
  }

  get fullName() {
    return `${this.name} ${this.surname}`;
  }
}
