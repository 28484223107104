import { Hydratable } from './hydratable';
import { ProductInventory } from './product_inventory';

export class Product implements Hydratable {
  id: number;
  categoryId: number;
  name: string;
  description: string;
  value: number;
  sku: string;
  publicPrice: number;
  barcodeSymbology: string;
  height: number;
  length: number;
  width: number;
  weight: number;
  minimumPurchaseQuantity: number;
  purchaseQuantity: number;
  active: boolean;
  inventory: ProductInventory;

  hydrateWith(input: any): this {
    Object.assign(this, input);

    if (input.inventory) {
      this.inventory = new ProductInventory().hydrateWith(input.inventory);
    }

    return this;
  }
}
