// Decorador que nos permite inyectar los servicios por inyeccion de dependencia
import { Injectable } from '@angular/core';
//
import { HttpClient, HttpHeaders } from '@angular/common/http';

// sirve para poder capturar lo que responden las peticiones ajax
import "rxjs/add/operator/map";

import {Observable} from 'rxjs/Observable';

// Referencia al fichero de configuracion global.ts
import { GLOBAL } from './global';

@Injectable()
export class UserService {
  public url: string;
  public identity;
  public token;

  constructor(private _http: HttpClient) {
    this.url = GLOBAL.url + '/v1';
  }

  update(user) {
    let json = JSON.stringify(user);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/user/update', json, { headers });
  }

  retrieve() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/user/retrieve', '', { headers });
  }
}
