import { Hydratable } from './hydratable';
import { Product } from './product';

export class Item implements Hydratable {
  id: number;
  item: string;
  width: number;
  length: number;
  height: number;
  weight: number;
  value: number;
  product: Product;
  quantity: number;
  maxQuantity: number;

  hydrateWith(input: any): this {
    Object.assign(this, input);

    if (input.product) {
      this.product = new Product().hydrateWith(input.product);
    }

    return this;
  }
}
