import { Hydratable } from './hydratable';

export class TemplateCosts implements Hydratable {
  id: number;
  orderPickupCost: number;
  packagePickupCost: number;
  insuranceRatio: number;

  hydrateWith(input: any): this {
    return Object.assign(this, input);
  }
}
