import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { PackagingService } from '../services/packaging.service';
import { ProductPackagingService } from '../services/product_packaging.service';
import { Packaging } from '../models/packaging';
import { ProductPackaging } from '../models/product_packaging';

@Component({
  selector: 'packaging-detail',
  templateUrl: '../views/packaging.detail.html',
  providers: [
    PackagingService,
    ProductPackagingService
  ]
})

export class PackagingDetailComponent implements OnInit {
  packaging: Packaging;
  productPackagings: Array<ProductPackaging>;
  errorMsg;
  loadingData: boolean;
  loadingProductPackagings: boolean;
  processing: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _packagingService: PackagingService,
    private _productPackagingService: ProductPackagingService
  ) {
    this.loadingData = false;
    this.loadingProductPackagings = false;
    this.processing = false;
    this.productPackagings = [];
  }

  ngOnInit() {
    this.getPackaging();
  }

  getPackaging() {
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      let id = +params['id'];

      this._packagingService.retrieve(id).subscribe(
        (response: any) => {
          this.packaging = new Packaging().hydrateWith(response.data);

          this.getProductPackagings(this.packaging);

          this.loadingData = false;
        },
        (errorResponse: any) => {
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  getProductPackagings(packaging) {
    this.loadingProductPackagings = true;

    this._productPackagingService.retrieveByPackaging(packaging).subscribe(
      (response: any) => {
        this.productPackagings = response.data;

        this.loadingProductPackagings = false;
      },
      (errorResponse: any) => {
        this.loadingProductPackagings = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  removeProductPackaging(productPackaging) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere.'
      return;
    }

    if (confirm('Esta acción no se puede deshacer')) {
      this.processing = true;
      this.loadingProductPackagings = true;

      this._productPackagingService.remove(productPackaging).subscribe(
        (response: any) => {
          this.getProductPackagings(this.packaging);
          this.processing = false;
        },
        (errorResponse: any) => {
          this.processing = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    }
  }
}
