import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class CsvService {

  constructor(
    private http: HttpClient
  ) { }

  private getCsv(path: string) {
    return this.http.get(path, {responseType: 'text'}).pipe(
      map((data: string) => data.split(/\r\n|\n/).filter((line: string) => line.length))
    );
  }

  getOrdersCsv() {
    return this.getCsv('assets/csv-examples/orders.csv');
  }

  getProductsCsv() {
    return this.getCsv('assets/csv-examples/products.csv');
  }

  getPackagingsCsv() {
    return this.getCsv('assets/csv-examples/packagings.csv');
  }
}
