// Decorador que nos permite inyectar los servicios por inyeccion de dependencia
import { Injectable } from '@angular/core';
//
import { HttpClient, HttpHeaders } from '@angular/common/http';

// sirve para poder capturar lo que responden las peticiones ajax
import "rxjs/add/operator/map";

import * as moment from 'moment';

// Referencia al fichero de configuracion global.ts
import { GLOBAL } from './global';

@Injectable()
export class DashboardService {
  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url + '/v1';
  }

  income(fromDate: moment.Moment = null, toDate: moment.Moment = null) {
    let queryStringVars = [];
    let queryString = '';

    if (fromDate !== null) {
      queryStringVars.push('from_date=' + fromDate.format("YYYY-MM-DD"));
    }
    if (toDate !== null) {
      queryStringVars.push('to_date=' + toDate.format("YYYY-MM-DD"));
    }

    queryString = queryStringVars.join('&');

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/dashboard/income?' + queryString, '', { headers });
  }
}
