import { Hydratable } from './hydratable';

export class OrderRate implements Hydratable {
  id: number;
  finalValue: number;
  planValue: number;
  insuranceCost: number;
  orderPickupCost: number;
  packagePickupCost: number;
  discount: number;
  weight: number;
  grossPrice: number;
  packagesCount: number;
  zoneMode: string;

  hydrateWith(input: any): this {
    return Object.assign(this, input);
  }
}
