import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';
import { Client } from '../models/client';

@Component({
  selector: 'client-edit',
  templateUrl: '../views/client.edit.html'
})

export class ClientEditComponent implements OnInit {
  client: Client;
  processing: boolean;
  errorMsg;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _clientService: ClientService
  ) {
    this.processing = false;
  }

  ngOnInit() {
    let clientIdentity = this._authService.getClientIdentity();

    this.client = new Client().hydrateWith(clientIdentity);
  }

  onSubmit(editClientForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    editClientForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._clientService.update(this.client).subscribe(
      (response: any) => {
        this.processing = false;
        this.errorMsg = '';

        let clientIdentity = response.data;

        if (!clientIdentity.id) {
          this.errorMsg = 'Ha ocurrido un error';
          return;
        }

        this._authService.setClientIdentity(clientIdentity);

        this._router.navigate(['/client/detail']);
      },
      (errorResponse: any) => {
        this.processing = false;

        editClientForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
