import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { AuthService } from './auth.service';

@Injectable()
export class AuthNotGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Si la URL requerida corresponde al login
    // Si el usuario está logueado, navegamos al home (no pasa el
    // guard).
    if (this._authService.isLoggedIn()) {
      this._router.navigate(['/']);
      return false;
    }
    // Si pasa el guard.
    return true;
  }
}
