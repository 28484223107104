import { Hydratable } from './hydratable';
import { PackagingInventory } from './packaging_inventory';

export class Packaging implements Hydratable {
  id: number;
  name: string;
  value: number;
  sku: string;
  publicPrice: number;
  supplierSku: string;
  height: number;
  length: number;
  width: number;
  dimensionsScope: number;
  weightScope: number;
  active: boolean;
  inventory: PackagingInventory;

  hydrateWith(input: any): this {
    Object.assign(this, input);

    if (input.inventory) {
      this.inventory = new PackagingInventory().hydrateWith(input.inventory);
    }

    return this;
  }
}
