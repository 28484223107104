import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';

import { Router }  from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';

import { AuthService } from '../auth/auth.service';

import { User } from '../models/user';
import { Client } from '../models/client';

@Component({
  selector: 'navbar',
  templateUrl: '../views/navbar.html'
})
export class NavbarComponent implements OnInit, OnDestroy {
  userLoggedIn;
  identity;
  clientIdentity;

  constructor(
    private _router: Router,
    private _renderer: Renderer2,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this._authService.userIdentity$.subscribe((user: User) => {
      this.userLoggedIn = user ? true : false;
      this.identity = user;

      if (this.userLoggedIn) {
        this._renderer.addClass(document.body, 'navbar-fixed-top');
      } else {
        this._renderer.removeClass(document.body, 'navbar-fixed-top');
      }
    });

    this._authService.clientIdentity$.subscribe((client: Client) => {
      this.clientIdentity = client;
    });

    this._authService.setObservables();
  }

  ngOnDestroy() {
    this._authService.userIdentity$.unsubscribe();
    this._authService.clientIdentity$.unsubscribe();
  }

  logout() {
    this._authService.logout().subscribe(
      (response: any) => {
        this._authService.clearStorage();

        this._router.navigate(['/login']);
      },
      (errorResponse: any) => {
        let errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        alert(errorMsg);
      }
    );
  }
}
