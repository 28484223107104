import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';
import { ClientAddressService } from '../services/client_address.service';
import { ClientAddress } from '../models/client_address';

@Component({
  selector: 'client-address-detail',
  templateUrl: '../views/client_address.detail.html',
  providers: [
    ClientAddressService
  ]
})

export class ClientAddressDetailComponent implements OnInit {
  clientAddress: ClientAddress;
  defaultClientAddress: ClientAddress;
  errorMsg;
  loadingData: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _clientAddressService: ClientAddressService
  ) {
    this.loadingData = false;
  }

  ngOnInit() {
    this.defaultClientAddress = this._authService.getClientIdentity().default_client_address;

    this.getClientAddress();
  }

  getClientAddress() {
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      let id = +params['id'];

      this._clientAddressService.retrieve(id).subscribe(
        (response: any) => {
          this.loadingData = false;

          this.clientAddress = new ClientAddress().hydrateWith(response.data);

          this.clientAddress.isDefault = this.clientAddress.id === this.defaultClientAddress.id;
        },
        (errorResponse: any) => {
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }
}
