import { Component, OnInit, Renderer2 } from '@angular/core';

import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'not-found',
  templateUrl: '../views/not_found.html'
})
export class NotFoundComponent implements OnInit {
  constructor(
    private _renderer: Renderer2,
    private _authService: AuthService
  ) {}

  ngOnInit() {

  }
}
