import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user';

@Component({
  selector: 'user-edit',
  templateUrl: '../views/user.edit.html'
})

export class UserEditComponent implements OnInit {
  public user: User;
  public processing: boolean;
  public errorMsg;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _userService: UserService
  ) {
    this.processing = false;
  }

  ngOnInit() {
    let identity = this._authService.getUserIdentity();

    this.user = new User().hydrateWith(identity);
  }

  onSubmit(editUserForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    editUserForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._userService.update(this.user).subscribe(
      (response: any) => {
        this.processing = false;
        this.errorMsg = '';

        let identity = response.data;

        if (!identity.id) {
          this.errorMsg = 'Ha ocurrido un error';
          return;
        }

        this._authService.setUserIdentity(identity);

        this._router.navigate(['/user/detail']);
      },
      (errorResponse: any) => {
        this.processing = false;

        editUserForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
