import { Client } from './../models/client';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user';

@Component({
  selector: 'activate',
  templateUrl: '../views/activate.html'
})

export class ActivateComponent implements OnInit {
  user: User;
  password2: string;
  activationToken: string;
  identity;
  token;
  processing: boolean;
  errorMsg: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
  ) {
    this.processing = false;
    this.errorMsg = '';

    this.user = new User();

    this.user.id = Number(this._route.snapshot.paramMap.get('userId'));
    this.activationToken = this._route.snapshot.paramMap.get('token');
  }

  ngOnInit() {
    console.log('lalala');
  }

  onSubmit(form: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    if (form.invalid) {
      this.errorMsg = 'Formulario no válido';
      return;
    }

    if (this.user.password !== this.password2) {
      this.errorMsg = 'Las contraseñas no coinciden';
      return;
    }

    form.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._authService.activate(this.user, this.activationToken).subscribe(
      (response: any) => {
        const accessToken = response.accessToken;
        const refreshToken = response.refreshToken;

        this.token = accessToken;

        if (this.token.length <= 1) {
          this.errorMsg = 'Ha ocurrido un error';
          return;
        }

        this._authService.setToken(this.token);
        this._authService.setRefreshToken(refreshToken);

        this.identity = new User().hydrateWith(response.user);

        if (!this.identity.id){
          this.errorMsg = 'Ha ocurrido un error';
          return;
        }

        this._authService.setUserIdentity(this.identity);

        const client = new Client().hydrateWith(response.user.client);

        this._authService.setClientIdentity(client);

        this._router.navigate(['/']);
      },
      (errorResponse: any) => {
        this.processing = false;

        form.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
