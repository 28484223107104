import { Service } from './../models/service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { OrderService } from '../services/order.service';
import { ClientAddressService } from '../services/client_address.service';
import { ProductService } from '../services/product.service';
import { PackagingService } from '../services/packaging.service';
import { Order } from '../models/order';
import { DeliveryMode } from '../models/delivery_mode';
import { Receiver } from '../models/receiver';
import { ClientAddress } from '../models/client_address';
import { Product } from '../models/product';
import { Packaging } from '../models/packaging';
import { Shipping } from '../models/shipping';
import { Item } from '../models/item';

@Component({
  selector: 'order-new',
  templateUrl: '../views/order.new.html',
  providers: [
    OrderService,
    ClientAddressService,
    ProductService,
    PackagingService
  ]
})

export class OrderNewComponent implements OnInit {
  order: Order;
  errorMsg;
  clientAddresses: Array<ClientAddress>;

  // Array de todos los productos disponibles.
  products: Array<Product>;

  totalProductsStock;

  // Array de todos la paquetería disponible.
  packagings: Array<Packaging>;

  totalPackagingsStock;

  loadingClientAddresses: boolean;
  loadingProducts: boolean;
  loadingPackagings: boolean;
  processing: boolean;

  // Array que guarda el producto seleccionado al agregar un nuevo item. Es un
  // array porque hay un producto seleccionado por cada paquete. Solo se setea
  // al momento de agregar un item, luego se resetea para dar lugar a agregar
  // otro item.
  selected_products: Array<Product>;

  // Array que guarda el packaging seleccionado de cada shipping.
  selected_packagings: Array<Packaging>;

  // Array que guarda la cantidad seleccionada del producto al agregar un
  // nuevo item. Idem anterior.
  selectedQuantities: Array<number>;

  // Array de arrays que guarda las cantidades seleccionadas de cada item de
  // cada shipping. Tiene la forma:
  // quantities[<indice-shipping>][<indice-item>]
  quantities: Array<Array<number>>;

  selectedPackagings

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _orderService: OrderService,
    private _clientAddressService: ClientAddressService,
    private _productService: ProductService,
    private _packagingService: PackagingService
  ) {
    this.clientAddresses = [];

    this.products = [];

    this.packagings = [];

    this.totalProductsStock = 0;
    this.totalPackagingsStock = 0;

    this.loadingClientAddresses = false;
    this.loadingProducts = false;
    this.loadingPackagings = false;
    this.processing = false;
    this.selected_products = [];
    this.selectedQuantities = [];
    this.quantities = [];
    this.selected_packagings = [];
  }

  ngOnInit() {
    let deliveryMode = new DeliveryMode().hydrateWith({
      id: 1,
      name: 'eiguadepo'
    });
    let receiver = new Receiver();
    let order = new Order();

    order.shippings = [];
    order.deliveryMode = deliveryMode;
    order.receiver = receiver;

    this.order = order;

    this.getProducts();
    this.getPackagings();
    this.addShipping();
  }

  // Obtiene todos los productos.
  getProducts() {
    this.loadingProducts = true;

    this._productService.retrieveAll(1).subscribe(
      (response: any) => {
        this.loadingProducts = false;

        this.products = response.data.map(product => new Product().hydrateWith(product));

        this.products.forEach((product: Product) => {
          // El product.inventory.freeStock es la cantidad de
          // productos que están disponibles para crear órdenes. No
          // se modifica sino hasta que se guarda la orden en el back.

          // Este muestra la cantidad de productos que están reservados
          // en los items de los paquetes. Se modifica cuando se
          // agregan items.
          product.inventory.reservedFrontStock = 0;

          // Este muestra la cantidad de productos que está libre para
          // ser tomado por los items de los paquetes. Se modifica
          // cuando se agregan items.
          product.inventory.freeFrontStock = product.inventory.freeStock;

          this.totalProductsStock += product.inventory.freeStock;
        });
      },
      (errorResponse: any) => {
        this.loadingProducts = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  // Obtiene toda la paquetería.
  getPackagings() {
    this.loadingPackagings = true;

    this._packagingService.retrieveAll(1).subscribe(
      (response: any) => {
        this.packagings = response.data.map(packaging => new Packaging().hydrateWith(packaging));

        this._packagingService.retrieveAllEigua().subscribe(
          (response: any) => {
            this.loadingPackagings = false;

            let eiguaPackagings = response.data.map(packaging => new Packaging().hydrateWith(packaging));

            this.packagings = this.packagings.concat(eiguaPackagings);

            this.packagings.forEach((packaging: Packaging) => {
              if (!packaging.inventory) {
                return;
              }

              // El packaging.inventory.available_stock es la cantidad de
              // packagings que están disponibles en el depósito. Esta
              // cantidad no se modifica.

              // Este muestra la cantidad de packagings que están
              // reservados en los shippings. Se modifica cuando se
              // selecciona un packaging.
              packaging.inventory.reservedFrontStock = 0;

              // Este muestra la cantidad de packagings que está libre
              // para ser tomado por los shippings. Se modifica  cuando se
              // selecciona un packaging.
              packaging.inventory.freeFrontStock = packaging.inventory.freeStock;

              this.totalPackagingsStock += packaging.inventory.freeStock;
            });
          },
          (errorResponse: any) => {
            this.loadingPackagings = false;

            this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
          }
        );
      },
      (errorResponse: any) => {
        this.loadingPackagings = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  // Obtiene todas las direcciones del cliente. No se está usando porque el
  // modo de envío es "eiguaDEPO", es decir se despachan los productos desde
  // el depósito de EIGUA, no a demanda (donde habría que buscar los paquetes
  // desde la dirección del cliente seleccionada).
  getClientAddresses() {
    this.loadingClientAddresses = true;

    this._clientAddressService.retrieveAll().subscribe(
      (response: any) => {
        this.loadingClientAddresses = false;

        this.clientAddresses = response.data.map(clientAddress => new ClientAddress().hydrateWith(clientAddress));

        let defaultClientAddressId = this._authService.getClientIdentity().defaultClientAddress.id

        this.clientAddresses.forEach((clientAddress: ClientAddress) => {
          if (clientAddress.id === defaultClientAddressId) {
            clientAddress.isDefault = true;
            this.order.clientAddress = clientAddress;
          }
        });
      },
      (errorResponse: any) => {
        this.loadingClientAddresses = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  onSubmit(orderNewForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    const service = new Service();
    service.id = 1;
    this.order.service = service;

    orderNewForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._orderService.create(this.order).subscribe(
      (response: any) => {
        this.processing = false;

        this._router.navigate(['/order', response.data.id]);
      },
      (errorResponse: any) => {
        this.processing = false;

        orderNewForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  // Agrega un item al listado de items del shipping correspondiente al índice
  // "i".
  addItem(i) {
    let product = this.selected_products[i];

    if (!product) {
      alert('Seleccione un producto');
      return;
    }

    let quantity = this.selectedQuantities[i];

    if (quantity == 0) {
      alert('Ingrese la cantidad de productos');
      return;
    }

    if (quantity < 0) {
      this.selectedQuantities[i] = 0;
      return;
    }

    let freeStock = product.inventory.freeFrontStock;

    if (quantity > freeStock) {
      // Si la cantidad ingresada supera el stock libre disponible,
      // hacemos que el valor del input sea igual al stock libre.
      this.selectedQuantities[i] = freeStock;

      // alert('No hay suficiente stock del producto');
      // return;
    }

    // Actualizamos el stock libre disponible (restando la cantidad
    // seleccionada) y el stock reservado disponible (sumando la cantidad).
    product.inventory.freeFrontStock -= quantity;
    product.inventory.reservedFrontStock += quantity;

    // Creamos una nueva instancia de Item con el máximo de unidades seteado
    // al valor del stock libre disponible anterior a la actualización de
    // stocks.
    let maxUnits = freeStock;
    let item = new Item();
    item.product = product;
    item.quantity = quantity;
    item.maxQuantity = maxUnits;

    // Actualizamos el valor de la máxima cantidad de unidades de todos los
    // items (de todos los shippings) que correspondan (que usan el mismo
    // producto).
    this.updateOtherItemsMaxQuantity(item, quantity);

    this.order.shippings[i].items.push(item);

    this.quantities[i].push(quantity);

    // Reseteamos los valores del producto y cantidad seleccionadas.
    this.selected_products[i] = null;
    this.selectedQuantities[i] = 0;

    // Actualizamos los valores del shipping (peso total y valor total).
    this.updateShippingData(this.order.shippings[i]);
  }

  // Elimina el item con índice "j" del shipping con índice "i". Se realiza la
  // actualización de stocks y de los datos del shipping (de acuerdo al
  // parámetro updateShippingData).
  removeItem(i, j, updateShippingData = true) {
    let quantity = this.order.shippings[i].items[j].quantity;

    // Al eliminar un item es necesario actualizar la cantidad máxima de
    // items de todos los otros items que usen el mismo producto.
    this.updateOtherItemsMaxQuantity(this.order.shippings[i].items[j], -quantity);

    // También es necesario actializar el stock libre disponible y el stock
    // reservado disponible.
    this.order.shippings[i].items[j].product.inventory.freeFrontStock += quantity;
    this.order.shippings[i].items[j].product.inventory.reservedFrontStock += quantity;

    // Se elimina el item "j" del array de items del shipping "i".
    this.order.shippings[i].items.splice(j, 1);
    this.quantities[i].splice(j, 1);

    if (updateShippingData) {
      this.updateShippingData(this.order.shippings[i]);
    }
  }

  // Se ejecuta cuando se dispara el evento "(change)" en el selector de
  // cantidad del item "j" del shipping "i".
  itemQuantityChange(i, j) {
    let changedItem = this.order.shippings[i].items[j];

    // Obtenemos el valor que tenía el input del array quantities. Entramos
    // con las claves "i" y "j".
    let oldValue = this.quantities[i][j];

    // El nuevo valor viene dado por el valor que tiene actualmente el item.
    let newValue = changedItem.quantity;

    // Si el valor ingresado en el input cantidad es mayor que la cantidad
    // máxima para ese input, o es menor a 1, seteamos el valor del input
    // igual valor que tenía anteriormente.
    if (changedItem.quantity > changedItem.maxQuantity || changedItem.quantity < 1) {
      changedItem.quantity = oldValue;
      return;
    }

    let movement = newValue - oldValue;

    // Seteamos el nuevo valor en el array quantities.
    this.quantities[i][j] = newValue;

    // Actualizamos los stocks según el movimiento generado.
    this.order.shippings[i].items[j].product.inventory.freeFrontStock -= movement;
    this.order.shippings[i].items[j].product.inventory.reservedFrontStock += movement;

    // Al modificar la cantidad de productos de un item, es necesario
    // actualizar la cantidad máxima de todos los items que usen el mismo
    // producto.
    this.updateOtherItemsMaxQuantity(changedItem, movement);

    this.updateShippingData(this.order.shippings[i]);
  }

  // Actualiza el valor máximo de las cantidades de los items que usen el
  // mismo producto que la instancia "changedItem", con el movimiento dado.
  updateOtherItemsMaxQuantity(changedItem, movement) {
    // Iteramos en todos los shippings de la orden.
    this.order.shippings.forEach((shipping: Shipping) => {
      // Luego iteramos en todos los items del shipping.
      shipping.items.forEach((item: Item) => {
        // Si el item no es el que cambió y el producto es el mismo,
        // actualizo la cantidad máxima.
        if (item != changedItem && item.product.id == changedItem.product.id) {
          item.maxQuantity -= movement;
        }
      });
    });
  }

  // Actualiza los datos calculados del shipping (peso total y valor total de
  // los items.
  updateShippingData(shipping: Shipping) {
    let itemsValue = 0;
    let weight = 0;

    shipping.items.forEach((item: Item) => {
      itemsValue += item.product.publicPrice * item.quantity;
      weight += item.product.weight * item.quantity;
    });

    shipping.itemsValue = itemsValue;
    shipping.weight = weight;
  }

  // Se ejecuta cuando se dispara el evento (change) del selector de
  // paquetería del shipping "i". Es necesario actualizar el stock de la
  // nueva paquetería seleccionada así como también de la paquetería que
  // estaba seleccionada anteriormente.
  packagingChange(i) {
    let oldPackaging = this.selected_packagings[i];
    let newPackaging = this.order.shippings[i].packaging;

    if (oldPackaging && oldPackaging.inventory) {
      oldPackaging.inventory.freeFrontStock += 1;
      oldPackaging.inventory.reservedFrontStock -= 1;
    }

    if (newPackaging && newPackaging.inventory) {
      this.order.shippings[i].packaging.inventory.freeFrontStock -= 1;
      this.order.shippings[i].packaging.inventory.reservedFrontStock += 1;
    }

    this.selected_packagings[i] = newPackaging;
  }

  // Agrega un nuevo shipping en blanco.
  addShipping() {
    let shipping = new Shipping();
    shipping.items = [];
    shipping.itemsValue = 0;
    shipping.weight = 0;
    shipping.packaging = null;

    this.order.shippings.push(shipping);
    this.quantities.push([]);
    this.selected_products.push(null);
    this.selectedQuantities.push(0);
    this.selected_packagings.push(null);
  }

  // Elimina el shipping con índice "i".
  removeShipping(i) {
    // Al eliminar un shipping, es necesario llamar al método para eliminar
    // los items (que se encarga de actualizar los stocks y las cantidades
    // máximas). Se le pasa el tercer parámetro en false porque no tiene
    // sentido en este punto actualizar los datos del shipping.
    this.order.shippings[i].items.forEach((item, j) => {
        this.removeItem(i, j, false);
    });

    // Actualizamos el stock del packaging que estaba seleccionado en el
    // shipping que se está eliminando.
    if (this.selected_packagings[i] && this.selected_packagings[i].inventory) {
      this.selected_packagings[i].inventory.freeFrontStock += 1;
      this.selected_packagings[i].inventory.reservedFrontStock -= 1;
    }

    this.order.shippings.splice(i, 1);
    this.quantities.splice(i, 1);
    this.selected_products.splice(i, 1);
    this.selectedQuantities.splice(i, 1);
    this.selected_packagings.splice(i, 1);

    // Si se borraron todos los shippings, agrego uno en blanco.
    if (this.order.shippings.length == 0) {
        this.addShipping();
    }
  }
}
