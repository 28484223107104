import { Hydratable } from './hydratable';
import { Item } from './item';
import { Packaging } from './packaging';
import { SystemState } from './system_state';

export class Shipping implements Hydratable {
  id: number;
  shippmentId: string;
  currentState: SystemState;
  items: Array<Item>;
  packaging: Packaging;
  itemsValue: number;
  weight: number;

  hydrateWith(input: any): this {
    Object.assign(this, input);

    this.items = input.items.map(item => new Item().hydrateWith(item));
    this.packaging = new Packaging().hydrateWith(input.packaging);
    this.currentState = new SystemState().hydrateWith(input.currentState);

    return this;
  }
}
