// Decorador que nos permite inyectar los servicios por inyeccion de dependencia
import { Injectable } from '@angular/core';
//
import { HttpClient, HttpHeaders } from '@angular/common/http';

// sirve para poder capturar lo que responden las peticiones ajax
import "rxjs/add/operator/map";

import { Observable } from 'rxjs/Observable';

// Referencia al fichero de configuracion global.ts
import { GLOBAL } from './global';

@Injectable()
export class ProductPackagingService {
  url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url + '/v1';
  }

  create(productPackaging) {
    let json = JSON.stringify({
      'productId': productPackaging.product.id,
      'packagingId': productPackaging.packaging.id,
      'maxUnits': productPackaging.maxUnits,
      'active': productPackaging.active
    });

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product-packaging/create', json, { headers });
  }

  retrieve(id) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product-packaging/retrieve/' + id, '', { headers });
  }

  retrieveByProduct(product) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product-packaging/retrieve-by-product/' + product.id, '', { headers });
  }

  retrieveByPackaging(packaging) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product-packaging/retrieve-by-packaging/' + packaging.id, '', { headers });
  }

  update(product) {
    let json = JSON.stringify(product);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product-packaging/update/' + product.id, json, { headers });
  }

  remove(productPackaging) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post(this.url + '/product-packaging/remove/' + productPackaging.id, '', { headers });
  }
}
