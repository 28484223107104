import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { OrderService } from '../services/order.service';
import { Order } from '../models/order';

import * as moment from 'moment';

@Component({
  selector: 'order-dashboard-list',
  templateUrl: '../views/order.dashboard_list.html',
  providers: [OrderService]
})

export class OrderDashboardListComponent implements OnInit {

  @Input() fromDate: moment.Moment;
  @Input() toDate: moment.Moment;

  @Output() processingEmitter: EventEmitter<boolean> = new EventEmitter();

  errorMsg;
  orders: Array<Order>;
  processing: boolean;
  loadingData: boolean;
  currentPage;
  paginationData;

  clientOrderId;
  trackingId;
  shippmentId;
  trackingIdSbmt;
  shippmentIdSbmt;
  clientOrderIdSbmt;
  fromDateSbmt;
  toDateSbmt;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _orderService: OrderService
  ) {
    this.orders = [];
    this.clientOrderId = '';
    this.fromDate = null;
    this.toDate = null;
    this.clientOrderIdSbmt = '';
    this.fromDateSbmt = null;
    this.toDateSbmt = null;
    this.loadingData = false;
    this.processing = false;
    this.errorMsg = '';
  }

  ngOnInit() {
    this.currentPage = 1;
    this.getAllOrders();
  }

  getAllOrders() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.processing = true;
    this.processingEmitter.emit(this.processing);
    this.errorMsg = '';
    this.loadingData = true;

    this._route.params.forEach((params: Params) => {
      this._orderService.retrieveAll('', '', '', this.fromDate.format('YYYY-MM-DD'), this.toDate.format('YYYY-MM-DD'), this.currentPage).subscribe(
        (response: any) => {
          this.processing = false;
          this.processingEmitter.emit(this.processing);
          this.errorMsg = '';

          this.paginationData = response.paginationData;

          this.orders = response.data.map(order => new Order().hydrateWith(order));

          this.loadingData = false;
        },
        (errorResponse: any) => {
          this.processing = false;
          this.processingEmitter.emit(this.processing);
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  goToPage(page) {
    this.currentPage = page;
    this.getAllOrders();
  }
}
