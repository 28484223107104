import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../auth/auth.service';

import { IncomeChartComponent } from '../income-chart/income-chart.component';
import { OrderDashboardListComponent } from './order.dashboard_list.component';

import * as moment from 'moment';

@Component({
  selector: 'dashboard',
  templateUrl: '../views/dashboard.html',
  providers: []
})

export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild(IncomeChartComponent, {static: false}) chart: IncomeChartComponent;
  @ViewChild(OrderDashboardListComponent, {static: false}) orderList: OrderDashboardListComponent;

  userIdentity;

  fromDate: moment.Moment;
  toDate: moment.Moment;

  processing: boolean;
  processingChart: boolean;
  processingOrders: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService
  ) {
    this.processingChart = true;
    this.processingOrders = true;
    this.processing = this.processingChart || this.processingOrders ? true : false;

    let toDate = moment();

    this.toDate = toDate;

    let fromDate = moment().subtract(4, 'week');

    this.fromDate = fromDate;
  }

  ngOnInit() {
    this.userIdentity = this._authService.getUserIdentity();
  }

  ngAfterViewInit() {
    this.chart.getChartData();
  }

  moment(date) {
    return moment(date);
  }

  dateRangeSubmit() {
    this.chart.getChartData();

    this.orderList.currentPage = 1;
    this.orderList.getAllOrders();
  }

  onProcessingChart(processing) {
    this.processingChart = processing;

    this.processing = this.processingChart || this.processingOrders ? true : false;
  }

  onProcessingOrders(processing) {
    this.processingOrders = processing;

    this.processing = this.processingChart || this.processingOrders ? true : false;
  }
}
