import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';
import { ClientAddressService } from '../services/client_address.service';
import { Client } from '../models/client';
import { ClientAddress } from '../models/client_address';

@Component({
  selector: 'client-detail',
  templateUrl: '../views/client.detail.html',
  providers: [
    ClientAddressService
  ]
})

export class ClientDetailComponent implements OnInit {
  client: Client;
  clientAddresses: Array<ClientAddress>;
  defaultClientAddress: ClientAddress;
  errorMsg;
  loadingData: boolean;
  loadingClientAddresses: boolean;
  processing: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _clientAddressService: ClientAddressService
  ) {
    this.clientAddresses = [];
    this.loadingData = false;
    this.loadingClientAddresses = false;
    this.processing = false;

    let clientIdentity = this._authService.getClientIdentity();
    this.defaultClientAddress = clientIdentity.default_client_address;
  }

  ngOnInit() {
    let clientIdentity = this._authService.getClientIdentity();

    this.client = new Client().hydrateWith(clientIdentity);

    this.getClientAddresses();
  }

  getClientAddresses() {
    this.loadingClientAddresses = true;

    this._clientAddressService.retrieveAll().subscribe(
      (response: any) => {
        this.loadingClientAddresses = false;

        this.clientAddresses = response.data.map(clientAddress => new ClientAddress().hydrateWith(clientAddress));
      },
      (errorResponse: any) => {
        this.loadingClientAddresses = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  removeClientAddress(clientAddress) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere.'
      return;
    }

    if (confirm('Esta acción no se puede deshacer')) {
      this.errorMsg = '';
      this.processing = true;
      this.loadingClientAddresses = true;

      this._clientAddressService.remove(clientAddress).subscribe(
        (response: any) => {
          this.getClientAddresses();
          this.processing = false;
        },
        (errorResponse: any) => {
          this.processing = false;
          this.loadingClientAddresses = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    }
  }
}
