import { Hydratable } from './hydratable';

export class DeliveryMode implements Hydratable {
  id: number;
  name: string;

  hydrateWith(input: any): this {
    return Object.assign(this, input);
  }
}
