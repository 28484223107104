import {Hydratable} from './hydratable';

export class ClientConfig implements Hydratable {
    id: number;
    urbanoShipper: string;
    urbanoPassword: string;
    deliveryDays: number;
    duplicityControl: boolean;

    hydrateWith(input: any): this {
        return Object.assign(this, input);
    }
}
