import { CsvService } from './../services/csv.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { PackagingService } from '../services/packaging.service';
import { Packaging } from '../models/packaging';

@Component({
  selector: 'packaging-batch',
  templateUrl: '../views/packaging.batch.html',
  providers: [
    PackagingService,
    CsvService
  ]
})

export class PackagingBatchComponent implements OnInit {

  csvHeader: string;
  csvFieldCount: number;
  loadingExampleCsv: boolean;
  loadingData: boolean;
  processing: boolean;
  errorMsg: string;
  packagings: Array<Packaging>;
  savedPackagings: Array<Packaging>;
  file: File;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _packagingService: PackagingService,
    private _csvService: CsvService
  ) {
    this.processing = false;
    this.loadingData = false;
  }

  ngOnInit() {
    this.errorMsg = '';
    this.resetBatch();
    this.loadExampleCsv();
  }

  loadExampleCsv() {
    this.loadingExampleCsv = true;

    this._csvService.getPackagingsCsv().subscribe(
      (lines: string[]) => {
        this.loadingExampleCsv = false;
        this.csvHeader = lines[0];
        this.csvFieldCount = this.csvHeader.split(';').length;
      }
    );
  }

  processFiles(fileList: FileList) {
    this.resetBatch();

    this.file = fileList[0];

    if (this.file.type != 'text/csv') {
        this.errorMsg = 'El archivo seleccionado no es un CSV';
        this.resetBatch();
        return;
    }

    this.readFileContent(this.file).then((content:string) => {
      this.processCSV(content);
    }).catch(error => console.log(error));
  }

  readFileContent(file: File) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = event => resolve((event.target as FileReader).result);
      reader.onerror = error => reject(error);
      reader.readAsText(file);
    });
  }

  processCSV(content: string) {
    this.errorMsg = '';
    let lines = content.split(/\r\n|\n/);
    let headerString = lines.shift();
    let headers = headerString.split(';');

    if (headers.length != 10) {
      this.errorMsg = 'El encabezado no es válido (debe tener 10 campos)';
      return;
    }

    if (lines.length > 1000) {
      this.errorMsg = 'El lote debe tener como máximo 1000 paqueterías';
      return;
    }

    let invalidLines: number = 0;
    let packagings = [];

    for (let line of lines) {
      if (line == "\n" || line.trim().length == 0) {
        continue;
      }
      let data = line.split(';');

      if (data.length != 10) {
        invalidLines++;
        continue;
      }

      let packaging = new Packaging().hydrateWith({
        name: data[0].trim(),
        sku: data[1].trim(),
        supplier_sku: data[2].trim(),
        value: parseFloat(data[3]),
        public_price: parseFloat(data[4]),
        height: parseInt(data[5]),
        length: parseInt(data[6]),
        width: parseInt(data[7]),
        dimensions_scope: parseInt(data[8]),
        weight_scope: parseInt(data[9]),
        active: true
      });

      packagings.push(packaging);
    }

    if (invalidLines > 0) {
      this.errorMsg = 'Líneas no válidas: ' + invalidLines;
      return;
    }

    if (!packagings.length) {
      this.errorMsg = 'La estructura del CSV es correcta pero no contiene ninguna paquetería.';
      return;
    }

    this.errorMsg = '';

    this.packagings = packagings;
  }

  resetBatch() {
    this.file = undefined;
    this.packagings = [];
    this.savedPackagings = [];
  }

  cancel() {
    this.errorMsg = '';
    this.resetBatch();
  }

  savePackagingBatch() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.loadingData = true;
    this.processing = true;
    this.errorMsg = '';

    this._packagingService.batchCreate(this.packagings).subscribe(
      (response: any) => {
        this.loadingData = false;
        this.processing = false;
        this.errorMsg = '';

        this.resetBatch();

        this.savedPackagings = response.data.map(packaging => new Packaging().hydrateWith(packaging));
      },
      (errorResponse: any) => {
        this.loadingData = false;
        this.processing = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
