import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { ProductService } from '../services/product.service';
import { ProductPackagingService } from '../services/product_packaging.service';
import { Product } from '../models/product';
import { ProductPackaging } from '../models/product_packaging';

@Component({
  selector: 'product-detail',
  templateUrl: '../views/product.detail.html',
  providers: [
    ProductService,
    ProductPackagingService
  ]
})

export class ProductDetailComponent implements OnInit {
  product: Product;
  productPackagings: Array<ProductPackaging>;
  errorMsg;
  loadingData: boolean;
  loadingProductPackagings: boolean;
  processing: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productService: ProductService,
    private _productPackagingService: ProductPackagingService
  ) {
    this.loadingData = false;
    this.loadingProductPackagings = false;
    this.processing = false;
    this.productPackagings = [];
  }

  ngOnInit() {
    this.getProduct();
  }

  getProduct() {
    this.loadingData = true;
    this.loadingProductPackagings = true;

    this._route.params.forEach((params: Params) => {
      let id = +params['id'];

      this._productService.retrieve(id).subscribe(
        (response: any) => {
          this.product = new Product().hydrateWith(response.data);

          this.getProductPackagings(this.product);

          this.loadingData = false;
        },
        (errorResponse: any) => {
          this.loadingData = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    });
  }

  getProductPackagings(product) {
    this.loadingProductPackagings = true;

    this._productPackagingService.retrieveByProduct(product).subscribe(
      (response: any) => {
        this.productPackagings = response.data;

        this.loadingProductPackagings = false;
      },
      (errorResponse: any) => {
        this.loadingProductPackagings = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  removeProductPackaging(productPackaging) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere.'
      return;
    }

    if (confirm('Esta acción no se puede deshacer')) {
      this.processing = true;
      this.loadingProductPackagings = true;

      this._productPackagingService.remove(productPackaging).subscribe(
        (response: any) => {
          this.getProductPackagings(this.product);
          this.processing = false;
        },
        (errorResponse: any) => {
          this.processing = false;

          this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
        }
      );
    }
  }
}
