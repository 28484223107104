import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ProductPackagingService } from '../services/product_packaging.service';
import { ProductService } from '../services/product.service';
import { PackagingService } from '../services/packaging.service';
import { ProductPackaging } from '../models/product_packaging';
import { Product } from '../models/product';
import { Packaging } from '../models/packaging';

@Component({
  selector: 'product_packaging-new',
  templateUrl: '../views/product_packaging.new.html',
  providers: [
    ProductPackagingService,
    ProductService,
    PackagingService
  ]
})

export class ProductPackagingNewComponent implements OnInit {
  productPackaging: ProductPackaging;
  products: Array<Product>;
  packagings: Array<Packaging>;
  errorMsg;
  processing: boolean;
  loadingData = false;
  loadingProducts: boolean;
  loadingPackagings: boolean;
  loadingBaseEntity: boolean;
  baseEntityName;
  pageTitle: string;
  product: Product;
  packaging: Packaging;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productPackagingService: ProductPackagingService,
    private _productService: ProductService,
    private _packagingService: PackagingService
  ) {
    this.pageTitle = 'Asignar producto/paquetería';
    this.processing = false;
    this.loadingProducts = false;
    this.loadingPackagings = false;
    this.loadingBaseEntity = false;
    this.products = [];
    this.packagings = [];
    this.product = null;
    this.packaging = null;
  }

  ngOnInit() {
    this.productPackaging = new ProductPackaging();
    this.productPackaging.maxUnits = 0;
    this.productPackaging.active = true;

    this.getBaseEntity();
    this.getProducts();
    this.getPackagings();
  }

  onSubmit(productPackagingNewForm: NgForm) {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    productPackagingNewForm.form.disable();
    this.processing = true;
    this.errorMsg = '';

    this._productPackagingService.create(this.productPackaging).subscribe(
      (response: any) => {
        this.errorMsg = '';

        if (this.baseEntityName == 'product') {
          this._router.navigate(['/product', this.productPackaging.product.id]);
        } else {
          this._router.navigate(['/packaging', this.productPackaging.packaging.id]);
        }
      },
      (errorResponse: any) => {
        this.processing = false;

        productPackagingNewForm.form.enable();

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  getBaseEntity() {
    this.loadingBaseEntity = true;

    this._route.params.forEach((params: Params) => {
      this.baseEntityName = params['base'];
      let id = +params['id'];

      if (this.baseEntityName == 'product') {
        this._productService.retrieve(id).subscribe(
          (response: any) => {
            this.product = new Product().hydrateWith(response.data);

            this.productPackaging.product = this.product;

            this.loadingBaseEntity = false;
          },
          (errorResponse: any) => {
            this.loadingBaseEntity = false;

            this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
          }
        );
      } else {
        this._packagingService.retrieve(id).subscribe(
          (response: any) => {
            this.packaging = new Packaging().hydrateWith(response.data);

            this.productPackaging.packaging = this.packaging;

            this.loadingBaseEntity = false;
          },
          (errorResponse: any) => {
            this.loadingBaseEntity = false;

            this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
          }
        );
      }
    });
  }

  getProducts() {
    this.loadingProducts = true;

    this._productService.retrieveAll().subscribe(
      (response: any) => {
        this.products = response.data.map(product => new Product().hydrateWith(product));

        this.loadingProducts = false;
      },
      (errorResponse: any) => {
        this.loadingProducts = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }

  getPackagings() {
    this.loadingPackagings = true;

    this._packagingService.retrieveAll().subscribe(
      (response: any) => {
        this.packagings = response.data.map(packaging => new Packaging().hydrateWith(packaging));

        this.loadingPackagings = false;
      },
      (errorResponse: any) => {
        this.loadingPackagings = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
