import { Hydratable } from './hydratable';

export class SystemState implements Hydratable {
  id: number;
  name: string;
  publicName: string;
  description: string;

  hydrateWith(input: any): this {
    return Object.assign(this, input);
  }
}
