import { Pipe, PipeTransform } from '@angular/core';

import {Receiver} from '../models/receiver';

@Pipe({name: 'formatReceiverAddress'})

export class FormatReceiverAddressPipe implements PipeTransform {
  transform(receiver: Receiver): string {
    let result = receiver.streetName + ' ' + receiver.streetNumber;

    if (receiver.floor || receiver.apartment) {
      result += ', ';
    }

    if (receiver.floor) {
      result += 'piso ' + receiver.floor;
    }

    if (receiver.apartment) {
      result += 'departamento ' + receiver.apartment;
    }

    if (receiver.betweenStreet1 && receiver.betweenStreet2) {
      result += ', entre ' + receiver.betweenStreet1 + ' y ' + receiver.betweenStreet2;
    } else {
      const cornerStreet = receiver.betweenStreet1 ? receiver.betweenStreet1 : receiver.betweenStreet2 ? receiver.betweenStreet2 : null;

      if (cornerStreet) {
        result += ', esq. ' + cornerStreet;
      }
    }

    result += ', ' + receiver.city;

    if (receiver.neighborhood) {
      result += ' (' + receiver.neighborhood + ')';
    }

    result += ', Pcia. de ' + receiver.province;

    return result;
  }
}
