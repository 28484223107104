import { Hydratable } from './hydratable';

export class ClientAddress implements Hydratable {
  id: number;
  zipcode: string;
  province: string;
  locality: string;
  neighborhood: string;
  street: string;
  number: string;
  floor: string;
  appartment: string;
  alias: string;
  observations: string;
  isDefault: boolean;

  hydrateWith(input: any): this {
    return Object.assign(this, input);
  }
}
