import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import { DashboardService } from '../services/dashboard.service';

import * as moment from 'moment';

@Component({
  selector: 'app-income-chart',
  templateUrl: './income-chart.component.html',
  styleUrls: ['./income-chart.component.css'],
  providers: [DashboardService]
})
export class IncomeChartComponent implements OnInit {

  @Input() fromDate: moment.Moment;
  @Input() toDate: moment.Moment;

  @Output() processingEmitter: EventEmitter<boolean> = new EventEmitter();

  lineChartData: ChartDataSets[] = [
      {data: [], label: 'Cargando...'},
  ];

  lineChartLabels: Label[] = [];

  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'DD/MM/YYYY',
            displayFormats: {
              day: 'D/MM'
            }
          }
        }
      ],
      yAxes: [
        {
          id: 'y-axis-income',
          position: 'left',
          ticks: {
            suggestedMin: 0,
            callback: function(value, index, values) {
              return '$' + value;
            }
          }
        },
        {
          id: 'y-axis-count',
          position: 'right',
          ticks: {
            suggestedMin: 0,
          }
        }
      ]
    }
  };

  lineChartColors: Color[] = [
      {
          backgroundColor: '#b1c7bc61',
      },
  ];

  lineChartLegend = true;
  lineChartType: ChartType = 'line';
  lineChartPlugins = [];

  loadingData: boolean;
  processing: boolean;
  errorMsg: string;

  totalIncome: number;
  totalOrderCount: number;
  totalShippingCount: number;

  constructor(
    private _dashboardService: DashboardService
  ) {
    this.loadingData = false;
    this.processing = false;
    this.processingEmitter.emit(this.processing);
    this.errorMsg = '';

    let toDate = moment();

    this.toDate = toDate;

    let fromDate = moment().subtract(1, 'week');

    this.fromDate = fromDate;
  }

  moment(date) {
    return moment(date);
  }

  ngOnInit() {

  }

  getChartData() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.loadingData = true;
    this.processing = true;
    this.processingEmitter.emit(this.processing);

    this._dashboardService.income(this.fromDate, this.toDate).subscribe(
      (response: any) => {
        this.loadingData = false;
        this.processing = false;
        this.processingEmitter.emit(this.processing);

        let data = response.data;
        let chartDataIncome = [];
        let chartDataOrderCount = [];
        let chartDataShippingCount = [];
        let chartLabels = [];
        let totalIncome = 0;
        let totalOrderCount = 0;
        let totalShippingCount = 0;

        for (let dataset of data) {
          chartDataIncome.push(dataset.income);
          chartDataOrderCount.push(dataset.orderCount);
          chartDataShippingCount.push(dataset.shippingCount);
          chartLabels.push(dataset.fday);

          totalIncome += dataset.income;
          totalOrderCount += dataset.orderCount;
          totalShippingCount += dataset.shippingCount;
        }

        this.totalIncome = totalIncome;
        this.totalOrderCount = totalOrderCount;
        this.totalShippingCount = totalShippingCount;

        this.lineChartData = [
          {
            data: chartDataIncome,
            label: 'Ingresos',
            yAxisID: 'y-axis-income',
            lineTension: 0
          },
          {
            data: chartDataOrderCount,
            label: 'Pedidos',
            yAxisID: 'y-axis-count',
            lineTension: 0
          },
          {
            data: chartDataShippingCount,
            label: 'Paquetes',
            yAxisID: 'y-axis-count',
            lineTension: 0
          }
        ];

        this.lineChartLabels = chartLabels;
      },
      (errorResponse: any) => {
        this.loadingData = false;
        this.processing = false;
        this.processingEmitter.emit(this.processing);

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
