import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // URL a la cual se está queriendo acceder.
    let requiredUrl = state.url;

    // Si el usuario está logueado, pasa el guard.
    if (this._authService.isLoggedIn()) {
      return true;
    }

    // Navegamos al login pasando como parámetro la URL que se solicitó sin
    // estar logueado, para redirigir a esta URL luego de un login exitoso.
    this._router.navigate(['/login'], {
      queryParams: {
        returnUrl: requiredUrl
      }
    });

    // No pasa el guard (no está logueado).
    return false;
  }
}
